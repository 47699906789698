<template>
    <div class='invite-list'  @click.self='close'>
        <div class='fixed_pc' @click.self='close'>
            <div class='pc' >
                <div class='head'>
                    邀请的客户({{total}})
            </div>
            <div class='content-box' ref='box'>
                <div class='item' v-for="(item,index) in userList" :key='index'>
                    <img v-default-avatar :src="item.wx_avatar" />
                    <span>{{item.wx_nickname|handleWxname}}</span>
                </div>
                <div class='img_load'>
                     <img src="@/assets/images/loading.gif" alt="" v-if='isShowLoadImg'>
                </div>
            </div>
            </div>
        </div>
    </div>
</template>
<script>
import {fetchUserInviteList} from '../api/index.js'

export default {
    props:{
        fetchParams:{
            default:()=>{
                return {

                }
            }
        }
    },
     data(){
         return{
              lName: 'lShouldLoadMore',
              userList:[],
              total:0,
              index:1,
              size:30,
              loadMoreHeight:5, // 当距离底部10大小加载
              isCanloadMore:true,
              timer:null,
              isShowLoadImg:true
         }
     },
     filters:{
         handleWxname(val){
             if(val.length>4){
                 return val.substring(0,4)+'...'
             }
             return val
         }
     },
     computed:{
         element(){
             return this.$refs.inviteList
         },
     },
     mounted(){
        this.lShouldLoadMore()
        this.hiddenBodyScroll()
        this.listenScroll()

    },
    destroyed(){
        this.showBodyScroll()
    },
    methods:{
        close(){
            this.$emit('close')
        },
       async lShouldLoadMore(){
           if(!this.isCanloadMore){
               return
           }
           this.isCanloadMore=false;
           this.isShowLoadImg=true;
            let params={
                ...this.fetchParams,
                "page":this.index,
                "page_size":this.size
            }
            let res=await fetchUserInviteList(params);
            if(res.code!==0){
                this.isShowLoadImg=false;
                return;
            }
            this.userList=this.userList.concat(res.data.list);
            this.total=res.data.total
            this.index+=1;
            this.isShowLoadImg=false;
            if (this.total >= this.userList.length) {
                this.isCanloadMore=true
            }

        },
        hiddenBodyScroll(){
            document.body.style["overflow-y"]='hidden'
        },
        showBodyScroll(){
             document.body.style["overflow-y"]='auto'
        },
        listenScroll(){
            let box=this.$refs.box;
            box.addEventListener('scroll',()=>{
                if(box.scrollTop+this.loadMoreHeight>box.scrollHeight-box.clientHeight){
                    this.lShouldLoadMore()
                }
            })
        }
    }
}
</script>
<style scoped lang='scss'>
.invite-list{
        position: fixed;
        top:0;
        left:0;
        bottom:0;
        right:0;
        background:rgba(0,0,0,.7);
        z-index:99;
        .fixed_pc{
            width: 100%;
            height: 9.6rem;
            border-top-right-radius: 0.16rem;
            border-top-left-radius: 0.16rem;
            box-sizing: border-box;
            position: fixed;
            bottom: 0;
            z-index:999;
            left:0;right:0;
            overflow:hidden;
            display: flex;
            justify-content: center;
            .pc{
                 width:7.5rem;
                 max-width: 7.5rem !important;
                 border-top-right-radius: 0.16rem;
                 border-top-left-radius: 0.16rem;
                 background: white;
                 height: 100%;
                 position: relative;
                 overflow: hidden;
                .head{
                color: rgba(50,50,51,1);
                font-size: 0.32rem;
                font-weight: 500;
                text-align: center;
                padding:0.26rem 0;
                border-bottom:1px solid rgba(0,0,0,.1);
                background: #ffffff;
                width: 100%;
                border-top-right-radius: 0.16rem;
                border-top-left-radius: 0.16rem;
                overflow:hidden;
                position: absolute;
                top:0;
                height: 0.96rem;
                box-sizing: border-box;
                z-index:1;
            }
            .content-box{
                background: white;
                width:100%;
                max-height: 9.6rem;
                box-sizing: border-box;
                overflow-y: auto;
                display: flex;
                flex-wrap: wrap;
                padding-top:0.96rem;
                overflow-y: auto;
                    .item{
                        width:1.5rem;
                        height:1.88rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        img{
                            width: 0.96rem;
                            height: 0.96rem;
                            border-radius: 100%;
                        }
                        span{
                            font-size: 0.24rem;
                            color: rgba(51,51,51,1);
                            font-weight: 400;
                        }
                    }
                    .img_load{
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        img{
                        width:0.5rem;
                        height: 0.5rem;
                        padding:0.2rem 0;
                    }
                    }

                }
            }
        }

}
</style>
