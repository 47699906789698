import NetWork from '@xiaoe/js-tools';
const CREATEPOSTER = 'forward_admin/wework_marketing/staff_task/my_task_detail';
const INVITEUSERLIST = 'forward_admin/wework_marketing/staff_task/my_invite_customer_list';

// 活动信息
export const fetchPosterInfo = (params) => {
    return NetWork.ajax({
        url: CREATEPOSTER,
        methods: 'post',
        params
    })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.error(error);
        });
};
// 员工邀请客户列表
export const fetchUserInviteList = (params) => {
    return NetWork.ajax({
        url: INVITEUSERLIST,
        methods: 'post',
        params
    })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.error(error);
        });
};
