<template>
    <div class='own-invite'  v-fixIPhoneX="{ paddingBottom: '0.68rem' }">
        <div class='info'>
            <span>已邀请：</span>
            <span class='num'>{{inviteNum}}</span>
            <span>个客户</span>
        </div>
        <div class='btn' @click='clickCheck'>
               {{this.inviteText}}
               <img src="@/assets/images/right.svg" alt="" v-if='inviteNum'>
        </div>
    </div>
</template>
<script>
export default {
    props:{
        info:{
            default:()=>{
                return {}
            }
        },
        inviteNum:0
    },
    computed:{
        inviteText(){
            if(!this.inviteNum){
                return '快去邀请客户吧~'
            }
            return '查看详情'
        }
    },
    methods:{
        clickCheck(){
            if(this.inviteNum){
                this.$emit('clickCheck')
            }
        }
    }
}
</script>
<style scoped lang='scss'>
.own-invite{
    width: 7.5rem;
    height:0.96rem;
    padding:0.28rem 0.32rem;
    box-sizing: border-box;
    background: #FFFFFF;
    display: flex;
    align-items: center;
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    color: rgba(0,0,0,1);
    font-size: 0.28rem;
    font-weight: 400;
    z-index:3;
    .info{
        .num{
            color:#ff9d0a;
        }
    }
    .btn{
        color: rgba(153,153,153,1);
        font-size: 14px;
        font-weight: 400;
        line-height: 0.56rem;
        text-align: center;
        img{
            position: relative;
            top:0.05rem;
        }
    }
}
.invite-number-n{
    color: #FF8A22;
}
</style>
