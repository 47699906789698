<template>
    <div class='dialog-task' >
        <div class='content-box' @click.self='close'>
            <div class='pc'>
                <div class='scroll-box'>
                 <div class='head'>
                    任务话术
                </div>
                <div class='content'>
                    {{content}}
                </div>
                 <div class='btn'>
                 <span class='copybtn' :data-clipboard-text='content' @click="copyLink">复制话术</span>
                </div>
            </div>
            </div>
        </div>
        <div class='shadow' @click.self='close'>
        </div>
    </div>
</template>
<script>
import Clipboard from 'clipboard';
import Toast from './toast.js';
export default {
    props:{
        content:''
    },
    methods:{
        close(e){
            this.$emit('close')
        },
        copyLink(){
             // 设置复制按钮
            let clipboard = new Clipboard('.copybtn');
            clipboard.on('success', function(e) {
                Toast('复制成功')
            });
            clipboard.on('error', function(e) {
                that.copyBoxShow = true;
                console.error('Action:', e.action);
                console.error('Trigger:', e.trigger);
            });
        }
    }
}
</script>
<style scoped lang="scss">
.dialog-task{
    .content-box{
        width: 100%;
        height: 9.6rem;
        border-top-right-radius: 0.16rem;
        border-top-left-radius: 0.16rem;
        box-sizing: border-box;
        position: fixed;
        bottom: 0;
        z-index:999;
        left:0;right:0;
        overflow:hidden;
        display: flex;
        justify-content: center;
        .pc{
            width:7.5rem;
            max-width: 7.5rem;
            border-top-right-radius: 0.16rem;
            border-top-left-radius: 0.16rem;
            background: white;
            height: 100%;
            position: relative;
        }
        .scroll-box{
            width: 100%;
            max-height:11.2rem;
            overflow-y: auto;
            font-size:2rem;
            box-sizing: border-box;
             .head{
                color: rgba(50,50,51,1);
                font-size: 0.32rem;
                font-weight: 500;
                text-align: center;
                padding:0.26rem 0;
                border-bottom:1px solid rgba(0,0,0,.1);
                background: #ffffff;
                width: 100%;
                border-top-right-radius: 0.16rem;
                border-top-left-radius: 0.16rem;
                overflow:hidden;
                position: absolute;
                top:0;
                height: 0.96rem;
                box-sizing: border-box;
            }
            .content{
                margin-top:0.96rem;
                padding-bottom:1.12rem;
                color: rgba(51,51,51,1);
                font-size: 0.28rem;
                font-weight: 400;
                padding:0.34rem 0.32rem;
                box-sizing: border-box;
                width:100%;
                word-break: break-all;
                max-height: 7.32rem;
                overflow-y: auto;
                white-space: pre-line;
            }
        }
        .btn{
            position:absolute;
            bottom:0;
            height: 1.12rem;
            font-size:0.36rem;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            background: #ffffff;
            .copybtn{
                width:6.72rem;
                height:0.8rem;
                display: flex;
                justify-content: center;
                align-items: center;
                color:#ffffff;
                background: rgba(20,114,255,1);
                font-size: 0.28rem;
                font-weight: 500;
                border-radius: 0.4rem;
            }
        }
    }
    .shadow{
        position: fixed;
        top:0;
        left:0;
        bottom:0;
        right:0;
        background:rgba(0,0,0,.7);
        z-index:99;
    }
}
</style>