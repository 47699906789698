<template>
    <div class='no-authority'>
        <img :src="imgPath" alt="">
        <span>{{text}}</span>
    </div>    
</template>
<script>
export default {
    props:{
        code:0 // 0-无权限 1-删除
    },
    computed:{
        text(){
            return this.code===404?'任务已被删除':'无权限查看'
        },
        imgPath(){
            return this.code===404?require('@/assets/images/delete.png'):require('@/assets/images/list.png')
        }
    }
}
</script>
<style scoped lang='scss'>
.no-authority{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top:5.12rem;
    img{
        width:1.76rem;
        height:1.76rem;
    }
    span{
        color: rgba(153,153,153,1);
font-size: 0.32rem;
font-weight: 400;
padding-top:0.3rem;
    }
}
</style>