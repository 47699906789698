<template>
    <div class="staff-task" v-loading="isLoading">
        <div v-if="isGetOver && !isError">
            <div class="part-1">
                <task-term @showDialog="showDialog" :content="taskTermContent"></task-term>
                <share-poster
                    :visible.sync="isShowPoster"
                    :posterData="posterData"
                    :posterImg="posterImg"
                    :qrCodeSrc="posterData.eAliveQrcode"
                    :isFixed="false"
                    @posterCompleted="posterCompleted"
                />
                <task-explain :taskExplain="taskExplain"></task-explain>
            </div>
            <own-invite @clickCheck="clickCheck" :inviteNum="inviteNum"></own-invite>
        </div>
        <dialog-task v-if="isShowDialog" :content="taskTermContent" @close="isShowDialog = false"></dialog-task>
        <dialog-list :fetchParams="params" @close="isShowInviteList = false" v-if="isShowInviteList"></dialog-list>
        <is-error v-if="isError" :code="code"></is-error>
    </div>
</template>
<script>
import taskTerm from './components/taskTerm.vue';
import dialogTask from './components/dialogTask';
import dialogList from './components/dialogList';
import ownInvite from './components/ownInvite';
import SharePoster from '@xiaoe/venus/dist/xiaoe/ui/h5/share-poster/share-poster.js';
import '@xiaoe/venus/dist/xiaoe/ui/h5/share-poster/share-poster.css';
import taskExplain from './components/taskExplain.vue';
import isError from './components/error.vue';
import { fetchPosterInfo, fetchUserInviteList } from './api/index.js';
import { getCookie } from '@/utils/cookies.js';
export default {
    components: {
        taskTerm,
        dialogTask,
        ownInvite,
        SharePoster,
        dialogList,
        taskExplain,
        isError
    },
    data() {
        return {
            isShowDialog: false,
            isShowInviteList: false,
            isLoading: false,
            taskTermContent: '',
            taskExplain: '',
            // 海报信息
            posterData: {},
            // 海报图片 缓存
            posterImg: '',
            isShowPoster: true,
            inviteNum: 0, // 邀请人数
            isGetOver: false, // 结果加载完毕
            code: 0 // 401无权限 402已删除
        };
    },
    created() {
        this.isLoading = true;
        this.loadInfo();
        // this.handlePosterInfo(ACTIVITYINFO.data)
    },
    computed: {
        params() {
            let task_id = this.$route.params.id;
            let app_id = getCookie('with_app_id');
            return {
                app_id,
                task_id
            };
        },
        isError() {
            return this.code === 403 || this.code === 404;
        }
    },
    methods: {
        loadInfo() {
            Promise.all([fetchPosterInfo(this.params), fetchUserInviteList(this.params)])
                .then((res) => {
                    this.code = res[0].code === 0 ? 0 : res[0].data.code;
                    // 无权限
                    if (res[0].data.code === 403) {
                        this.isLoading = false;
                        return;
                    }
                    // 已删除
                    if (res[0].data.code === 404) {
                        this.isLoading = false;
                        return;
                    }
                    if (res[0].code !== 0) {
                        this.code = 401;
                        this.isLoading = false;
                        return;
                    }
                    this.handlePosterInfo(res[0].data);
                    if (res[1].code === 0) {
                        this.handldeInviteInfo(res[1]);
                    }
                })
                .catch((e) => {
                    console.error(e);
                });
        },
        handldeInviteInfo(res) {
            this.inviteNum = res.data.total;
        },
        handlePosterInfo(data) {
            // 话术
            this.taskTermContent = data.task_template;
            // 描述
            this.taskExplain = data.task_desc;
            // 海报组件数据
            this.posterData = data.poster_conf; // 基本坐标信息
            this.posterData.nickname = data.user_name;
            this.posterData.avatarSrc = data.user_avatar;
            this.posterData.qrcodeSrc = data.share_url;
            this.posterData.eAliveQrcode = data.e_alive_qrcode; // 鹅直播小程序二维码
            this.isGetOver = true;
        },
        showDialog() {
            this.isShowDialog = true;
        },
        // todo 弹窗 or 新页面
        clickCheck() {
            this.isShowInviteList = true;
        },
        posterCompleted(img) {
            // 数据请求完毕
            this.isLoading = false;
            this.posterImg = img;
        }
    }
};
</script>
<style lang="scss" scoped>
.staff-task {
    background: #f5f5f5 !important;
    .part-1 {
        width: 100%;
        box-sizing: border-box;
        padding: 0.16rem 0.32rem 0.96rem 0.32rem;
        .poster {
            margin: 0.32rem 0;
        }
    }
}
</style>
<style>
#app-layout {
    background: #f5f5f5 !important;
    min-height: 100vh;
}
</style>
