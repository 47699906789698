<template>
    <div class='task-term' v-if='content'>
        <!-- <span class='title'>任务话术</span>
        <div class='btn'>
            <div class='common btn-check' @click='check'>查看</div>
            <div class='common btn-copy copybtn' :data-clipboard-text='content' @click="copyLink">复制</div>
        </div> -->
        <div class='task-left'>
            <div class='box'>
                <div class='title'>
                任务话术
                </div>
                <div class='content'>
                    <div>{{showContent}}<span v-if='this.overflowLetter' @click='check' class='more'>更多<img src="@/assets/images/right.svg" alt=""></span></div>
                    
                </div>
            </div>
        </div>
        <div class='task-right'>
            <span class='copybtn' :data-clipboard-text='content' @click="copyLink">复制</span>
        </div>
    </div>    
</template>
<script>
import Clipboard from 'clipboard';
import Toast from './toast.js';
export default {
    props:{
        content:''
    },
    computed:{
        showContent(){
            if(!this.overflowLetter){
                return this.content
            }
            return this.content.substring(0,this.MAXLETTER)+'...'
        },
        overflowLetter(){
            return this.MAXLETTER<this.content.length
        }
    },
    data(){
        return{
            MAXLETTER:27,
        }
    },
    methods:{
        check(){
            this.$emit('showDialog')
        },
        copyLink(){
             // 设置复制按钮
            let clipboard = new Clipboard('.copybtn');
            clipboard.on('success', function(e) {
                console.log(e)
                Toast('复制成功')
            });
            clipboard.on('error', function(e) {
                that.copyBoxShow = true;
                console.error('Action:', e.action);
                console.error('Trigger:', e.trigger);
            });
        }
    }
}
</script>
<style scoped lang='scss'>
.task-term{
   font-size:0.36rem;
   width: 100%;
   height: 1.92rem;
   background:#ffffff ;
   display: flex;
   box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.08);
   border-radius: 0.16rem;
   position: relative;
   z-index: 9;
   .task-left{
       width: 5.55rem;
       box-sizing: border-box;
       padding:0.24rem 0 0.24rem 0;
       .box{
           min-height: 100%;
           border-right:1px solid rgba(0,0,0,.1);
           width: 5.55rem;
           padding:0 0.38rem 0 0.32rem;
           box-sizing: border-box;
       }
       .title{
            font-size:0.32rem;
            color: rgba(51,51,51,1);
            font-weight:500;
            margin-bottom:0.16rem;
       }
       .content{
            display: flex;
            overflow : hidden;
            color: rgba(153,153,153,1);
            font-size:0.28rem;
           word-break: break-all;
            .more{
                color: rgba(51,51,51,1);
                margin-left:0.2rem;
            }
            img{
                    position: relative;
                    top: 0.05rem;
            }
       }
   }
   .task-right{
       font-size:0.28rem;
       font-weight: 500;
       width:1.34rem;
       display: flex;
       align-items: center;
       justify-content: center;
       color:#1472ff;
   }
}
</style>