<template>
    <div class='task-explain' v-if='taskExplain'>
        <div class='title'>
            任务描述
        </div>
        <div class='content'>
            {{taskExplain}}
        </div>
    </div>    
</template>
<script>
export default {
    props:{
        taskExplain:''
    }
}
</script>
<style scoped lang='scss'>
.task-explain{
    width: 100%;
    box-sizing: border-box;
    padding:0.32rem;
    background: rgba(255,255,255,1);
    margin-top: 0.16rem;
    border-radius: 0.16rem;
    margin-bottom:0.96rem;
    .title{
        color: rgba(51,51,51,1);
        font-size: 0.32rem;
        font-weight: 500;
    }
    .content{
        color: rgba(51,51,51,1);
        font-size: 0.28rem;
        font-weight: 400;
        word-break: break-all;
        margin-top:0.16rem;
        white-space: pre-line;
    }

}
</style>