// Created by ChazzChen on 2018/3/14
// toast插件，接受三个参数
// text => 展示的文字
// time => 展示的时长，默认为1000ms
// cb   => toast结束的回调
let toastZIndex = 11000;

const toast = (text = "这是一个toast", time = 1000, cb = null, icon = "") => {
    const toast = document.createElement("div");
    let toastIcon = icon ? `<i class="iconfont toastIcon ${icon}"></i>` : "";
    let widthStyle = icon ? `.minWidth { min-width:2.48rem }` : "";
    toast.className = "toast-wrap";
    toast.id = "SpToast";
    toast.innerHTML = `
        <style>
            .toast-wrap { opacity: 0; position: fixed; left: 0; top: 0; width: 100%; height: 100%; z-index: ${toastZIndex++}; transition: opacity .1s; display: flex; flex-direction:column; align-items:center; justify-content:center;}
            .toast { padding:0.24rem 0.2rem; max-width: 6.5rem;  background: rgba(17, 17, 17, 0.7); display: flex; flex-direction:column; align-items:center; border-radius: 5px;}
            .toast-text { overflow: hidden; text-overflow: ellipsis; height: inherit; line-height: inherit; font-size: .32rem; color: #fff;  }
            .opacity-1 { opacity: 1; }
            ${widthStyle}
            .toastIcon { font-size: 0.48rem; color: #fff; margin-bottom:0.08rem }
        </style>
        <div class="toast minWidth">
        ${toastIcon}
            <div id="SpToastText" class="toast-text">${text}</div>
        </div>
    `;

    const showToast = () => {
        document.body.appendChild(toast);
        setTimeout(() => {
            toast.className += " opacity-1";
            hideToast();
        }, 1);
    };

    const hideToast = () => {
        setTimeout(() => {
            toast.className = "toast-wrap";
            removeToast();
            toast.addEventListener("transitionend", removeToast);
        }, time);
    };

    const removeToast = () => {
        cb && typeof cb === "function" && cb();
        document.body.removeChild(toast);
        toast.removeEventListener("transitionend", removeToast);
    };

    showToast();
};

export default toast;
